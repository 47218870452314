import React, { Component } from 'react';
import { PATIENT_INFO } from '../../utils/constant';
import LS_SERVICE from '../../utils/localStorage';
import Axios from 'axios';
import moment from "moment";

class SubHeaderMain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patientInfo: {},
        };
    }

    componentDidMount() {
      this.getPatientBannerData()
    }

    getPatientBannerData = () => {
        const params = {
            patient_id: LS_SERVICE.get("call_patient_id"),
            reference_id: LS_SERVICE.get("reference_id"),
            reference_type: LS_SERVICE.get('reference_type')
        };

        Axios.get(PATIENT_INFO(params))
            .then(response => {
                const results = response.data.data.results || null;

                // Handle success: do something with the results, e.g., update state
                if (results) {
                    this.setState({ patientInfo: results });
                } else {
                    console.log("No results found.");
                }
            })
            .catch(error => {
                // Handle the error: log it or show a user-friendly message
                console.error("An error occurred while fetching patient data:", error);
            });
    };

    getPatientAge(dob) {
        var ageD = Math.floor(moment().diff(moment(dob, 'YYYY-MM-DD'), 'D') / (1000 * 3600 * 24));
        var ageM = moment().diff(moment(dob, 'YYYY-MM-DD'), 'M');
        var ageY = ageM / 12;
        var remainM = ageM % 12;
        ageY = Math.floor(ageY);
        var ageText = '';
        if (ageM == 0)
            ageText = ageD + ' Day' + (ageD == 1 ? '' : 's');
        else if (ageY < 1)
            ageText = ageM + ' Month' + (ageM == 1 ? '' : 's');
        else if (ageY <= 5) {
            if (remainM != 0) {
                ageText = ageY + ' Year' + (ageY == 1 ? '' : 's ') + remainM + ' Month' + (remainM == 1 ? '' : 's');
            } else {
                ageText = ageY + ' Year' + (ageY == 1 ? '' : 's');
            }
        }
        else
            ageText = ageY + ' Years';
        return ageText;
    }

    // Render method to return the JSX
    render() {
        const { patientInfo } = this.state;
        return (
            <div className='row mb-2'>
            <div className="col-12 px-2">
                <div className="card patient-card patient-red mb-2 p-2" role="alert" style={{ backgroundColor: '#ffe6e8', borderColor: '#af4a53', color: '#333', border: '1px solid #af4a53' }}>
                    <div className="row">
                        <div className="col">
                            <div className="row" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <div className="col-4" style={{ maxWidth: '33%', flex: '0 0 50%' }}>
                                    <p className="text-dark m-0" style={{ marginBottom: '0px' }}><label className="text-muted mb-0 banner-row" style={{ fontFamily: 'Roboto, sans-serif', width: '100px', marginBottom: '0px', display: 'inline-block' }}>Name</label><label className="font-weight-medium mb-0" style={{ fontFamily: 'Roboto, sans-serif', display: 'inline-block', marginBottom: '0px' }}>{patientInfo.title_name != undefined ? patientInfo.title_name : null}&nbsp;
                                        {patientInfo.first_name !== undefined ? patientInfo.first_name : null}&nbsp;{patientInfo.middle_name !== undefined ? patientInfo.middle_name : null}&nbsp;{patientInfo.last_name !== undefined ? patientInfo.last_name : null}</label></p>
                                </div>

                                <div className="col-4" style={{ maxWidth: '33%', flex: '0 0 50%' }}>
                                    <p className="text-dark m-0" style={{ marginBottom: '0px' }}><label className="text-muted mb-0 banner-row" style={{ fontFamily: 'Roboto, sans-serif', width: '100px', marginBottom: '0px', display: 'inline-block' }}>Age/Gender</label><label className="font-weight-medium mb-0" style={{ fontFamily: 'Roboto, sans-serif', display: 'inline-block', marginBottom: '0px' }}>{patientInfo.dob && patientInfo.age !== null ? this.getPatientAge(patientInfo.dob) : null}
                                        {patientInfo.gender !== undefined && patientInfo.gender !== null ? (patientInfo.gender == 'Male' ? ' (M)' : ' (F)') : null}</label></p>
                                </div>

                                <div className="col-4" style={{ maxWidth: '33%', flex: '0 0 50%' }}>
                                    <p className="text-dark m-0" style={{ marginBottom: '0px' }}><label className="text-muted mb-0 banner-row" style={{ fontFamily: 'Roboto, sans-serif', width: '100px', marginBottom: '0px', display: 'inline-block' }}>UHID</label><label className="font-weight-medium mb-0" style={{ fontFamily: 'Roboto, sans-serif', display: 'inline-block', marginBottom: '0px' }}>{this.state.patientInfo.uhid}</label></p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        );
    }
}

export default SubHeaderMain;
