import React, { Component } from 'react'
import Axios from 'axios';
import LS_SERVICE from '../../../utils/localStorage';
import { Link } from 'react-router-dom'
import { Form, Select, Modal } from 'antd';
import PDFImg from '../../../assets/images/ic-pdf.png';
import {
  LOADER_RED,
  PATIENT_IP_SURGERY_LIST
} from '../../../utils/constant';
import moment from 'moment';
import Moment from 'react-moment';
import qs from 'qs';

const { Option } = Select;

export default class PatientSurgery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedEncounterData: props.selectedEncounterData,
      surgeryListData : null,
      loader: true,
      showRxModal: false,
      rxPdfPath: null,
    }
  }

  async componentDidMount() {
    Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
    await this.getPatientSurgeryData();
    
  }

  async getPatientSurgeryData() {
    const { selectedEncounterData } = this.state;
    let PARAMS = {
      patientId: selectedEncounterData.registrationId,
      encounterId: selectedEncounterData.encounter_id,
      enterpriseId: selectedEncounterData.enterpriseId,
      facilityId: selectedEncounterData.facilityId,
    }
    await Axios.post(PATIENT_IP_SURGERY_LIST, qs.stringify(PARAMS))
    .then(async res => {
      let surgeryListData =  res.status ? res.data.data : null;
      await this.setState({
        surgeryListData: surgeryListData,
        loader: false
      });
    })
  }

  PatientSurgeryListLoad() {
    try {
      if ((!this.state.surgeryListData || this.state.surgeryListData.length === 0)) {
        let htmlData = (
          <tr>
            <td className="inner_table text-center" colSpan={10}>No Records Found.</td>
          </tr>
        )
        return htmlData;
      }
      var { surgeryListData } = this.state;
      var finalOrderSpan = {};
      if (surgeryListData && surgeryListData.length > 0) {
        surgeryListData.sort(function (a, b) {
          return new Date(b.surgery_request_id) - new Date(a.surgery_request_id);
        });
        surgeryListData.sort(function (a, b) {
          return new Date(b.surgery_date) - new Date(a.surgery_date);
        });
        var orderSpan = 1;
        var current_order = '';
        var finalOrderSpan = {};
        for (const orderinfo of surgeryListData) {
          if (orderinfo.surgery_request_id == current_order) {
            orderSpan += 1
          }
          else {
            orderSpan = 1;
          }
          finalOrderSpan[orderinfo.surgery_request_id] = orderSpan;
          current_order = orderinfo.surgery_request_id
        }
        var current_order_span = '';
        var already_spanned = 'Y';
        var finalOrder = []
        for (const orderinfo of surgeryListData) {
          if (orderinfo.surgery_request_id == current_order_span) {
            already_spanned = 'N';
            orderinfo.spanRow = finalOrderSpan[orderinfo.surgery_request_id];
            orderinfo.spaned = already_spanned;
          }
          else {
            already_spanned = 'Y';
            orderinfo.spanRow = finalOrderSpan[orderinfo.surgery_request_id];
            orderinfo.spaned = already_spanned;
          }
          current_order_span = orderinfo.surgery_request_id
          finalOrder.push(orderinfo)
        }
        surgeryListData = finalOrder;
      }
      return surgeryListData.map((result, i) => {
        let htmlData = (
          <tr key={i}>
            {result.spaned == 'Y' ? <td rowSpan={result.spanRow}>{result.surgery_request_no}</td> : null}
            <td>{result.surgery_name}</td>
            <td>{result.priority}</td>
            <td>{result.ordered_by}</td>
            <td>{result.status}</td>
            <td>{result.surgery_date != '0001-01-01T00:00:00' ? <Moment parse="YYYY-MM-DD HH:mm:ss" date={result.surgery_date} format="DD MMM YYYY | hh:mm A" /> : ''}</td>
            <td>{result.surgeon_doctor}</td>
            <td>{result.otName}</td>
            <td>
              {
                result.bookingDate && result.bookingDate != null && result.bookingDate != '0001-01-01T00:00:00'
                  ?
                  <>
                    <Moment parse="YYYY-MM-DD HH:mm:ss" date={result.bookingDate} format="DD MMM YYYY" /> <br />
                    {result.bookingFromTimeStr} - {result.bookingToTimeStr}
                  </>
                  :
                  <>
                    {
                      result.surgery_date != '0001-01-01T00:00:00'
                        ?
                        <>
                          <Moment parse="YYYY-MM-DD HH:mm:ss" date={result.surgery_date} format="DD MMM YYYY" /> <br />
                        </>
                        :
                        null
                    }
                  </>
              }
            </td>
          </tr>
        )
        return htmlData;
      });
    } catch (err) {
      console.log('PatientSurgeryListLoad: ', err);
    }
  }

  
  render() {
    const { loader } = this.state;
    return (
      <>
        { loader ? 
          <div className='row mt-3'>
            <div className="col-12  text-center mt-4">{LOADER_RED}
            </div>
          </div>
          :
          <div className='row mt-3'>
            <div className='col-12'>
              <div className="table-responsive">
                <table className='table table-bordered'>
                    <thead>
                        <tr>
                          <th width="10%" className='px-1 py-2' style={{verticalAlign:'top'}}>SURGERY REQUEST ID</th>
                          <th width="15%" className='px-1 py-2' style={{verticalAlign:'top'}}>SURGERY NAME</th>
                          <th width="10%" className='px-1 py-2' style={{verticalAlign:'top'}}>PRIORITY</th>
                          <th width="10%" className='px-1 py-2' style={{verticalAlign:'top'}}>ORDERING PRACTITIONER</th>
                          <th width="10%" className='px-1 py-2' style={{verticalAlign:'top'}}>STATUS</th>
                          <th width="15%" className='px-1 py-2' style={{verticalAlign:'top'}}>REQUESTED DATE</th>
                          <th width="10%" className='px-1 py-2' style={{verticalAlign:'top'}}>SURGEON/TEAM</th>
                          <th width="10%" className='px-1 py-2' style={{verticalAlign:'top'}}>OT NAME</th>
                          <th width="10%" className='px-1 py-2' style={{verticalAlign:'top'}}>SCHEDULE</th>
                        </tr>
                    </thead>
                    <tbody>
                      {this.PatientSurgeryListLoad()}
                    </tbody>
                </table>
              </div>
            </div>
          </div>
        }

        <Modal
          title="View Case Sheet"
          visible={this.state.showRxModal}
          footer={false}
          width={850}
          onCancel={() => { this.setState({ showRxModal: false, rxPdfPath: null }) }}
          style={{ top: 20 }}
        >
          <div className="row">
              <div className="col-12 my-3 text-center">
                  {this.state.rxPdfPath != null ? <iframe src={this.state.rxPdfPath} frameBorder="0" scrolling="no" style={{ overflow: 'hidden' }} width="100%" height="700">
                      {LOADER_RED}
                  </iframe> : null}
              </div>
          </div>
        </Modal>
      </>
    )
  }
}
